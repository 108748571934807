import React from 'react'
import Layout from '../../components/layout'
import {OpeningTimes} from "../../components/openingTimes";
import {dentists} from "../team";
import SEO from "../../components/seo";
import imgLogo from '../../images/pipers_logo.png'

const Page = () => {
  return (
    <Layout pageTitle="Fee Guide">
      <SEO title="Fee Guide"/>
      <div className="info-right-panel">
        <div className="logo">
          <img id="logo-img" src={imgLogo} alt="Pipers Dental Practice Logo"
               title="Pipers Dental Practice"/><br/>
          <div className="address">
				<span className="address">
          <span className="address-line">42A Station Road East</span>
				  <span className="address-line">Oxted, Surrey RH8 0PG</span>
        </span>
            <span className="telephone">Tel. 01883 712486</span>
          </div>
        </div>
        <div className="info">
          <h3 className="info-title">Practice Opening Hours</h3>
          <p className="general-text"><OpeningTimes /></p>
          <br/>
          <br/>
          <h3 className="info-title">Dentists</h3>
          <ul className="dentists">
            {dentists.map((_dentist, idx) => (
              <li key={idx}><span className="general-text">{_dentist.name}</span> <span className="gdc-number"><abbr title="General Dental Council Registration Number">GDC</abbr> {_dentist.gdcNumber}</span></li>
            ))}
          </ul>
        </div>
      </div>
      <div id="fee-guide">
        <h2 className="section-title">Fee Guide</h2>
        <table padding="0" spacing="0" border="0" className="general-text">
          <thead>
          <tr>
            <td colSpan="2">Diagnosis</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Routine examination</td>
            <td>£60</td>
          </tr>
          <tr>
            <td>Childs check up</td>
            <td>Free if parent is a regular attender <small>(at least once a year)</small>; otherwise £20</td>
          </tr>
          <tr>
            <td>New patient consultation</td>
            <td>£60</td>
          </tr>
          <tr>
            <td>Small X-rays</td>
            <td>£14</td>
          </tr>
          <tr>
            <td>Scale and polish / Hygiene Appointment</td>
            <td>£75</td>
          </tr>
          <tr>
            <td>Emergency (same day) appointment</td>
            <td>£95 + any work required</td>
          </tr>
          </tbody>
        </table>
        <table padding="0" spacing="0" border="0" className="general-text">
          <thead>
          <tr>
            <td colSpan="2">Conservation</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              Metal (amalgam) filling:
            </td>
            <td>From £195 to £270</td>
          </tr>
          <tr>
            <td>
              White (mercury free) filling:
            </td>
            <td>From £195 to £270</td>
          </tr>
          </tbody>
        </table>

        <table padding="0" spacing="0" border="0" className="general-text">
          <thead>
          <tr>
            <td colSpan="2">Orthodontics</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Invisible braces (both arches)</td>
            <td>From £4,200</td>
          </tr>
          <tr>
            <td>6 Month Smile</td>
            <td>From £4,200</td>
          </tr>
          </tbody>
        </table>

        <table padding="0" spacing="0" border="0" className="general-text">
          <thead>
          <tr>
            <td colSpan="2">Crowns and Inlays</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Range of crowns available</td>
            <td>From £600 to £790</td>
          </tr>
          </tbody>
        </table>

        <table padding="0" spacing="0" border="0" className="general-text">
          <thead>
          <tr>
            <td colSpan="2">Root Canal Treatment</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Dependent on tooth</td>
            <td>From £479</td>
          </tr>
          </tbody>
        </table>

        <table padding="0" spacing="0" border="0" className="general-text">
          <thead>
          <tr>
            <td colSpan="2">Extractions</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Extractions</td>
            <td>From £250</td>
          </tr>
          </tbody>
        </table>

        <table padding="0" spacing="0" border="0" className="general-text">
          <thead>
          <tr>
            <td colSpan="2">Implants</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Implants</td>
            <td>From £2,700</td>
          </tr>
          </tbody>
        </table>

        <table padding="0" spacing="0" border="0" className="general-text">
          <thead>
          <tr>
            <td colSpan="2">Bridges</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Bridges</td>
            <td>From £790 per unit</td>
          </tr>
          </tbody>
        </table>

        <table padding="0" spacing="0" border="0" className="general-text">
          <thead>
          <tr>
            <td colSpan="2">Dentures</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Dependent on type</td>
            <td>From £1050 to £2,100</td>
          </tr>
          </tbody>
        </table>

        <table padding="0" spacing="0" border="0" className="general-text">
          <thead>
          <tr>
            <td colSpan="2">Cosmetic</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Veneers</td>
            <td>From £690 to £790</td>
          </tr>
          <tr>
            <td>Botulinum toxin</td>
            <td>From £195 to £500 (to be discussed at consultation)</td>
          </tr>
          </tbody>
        </table>


        <div className="general-text detail" id="feeguide-smalltext">
          <div>
            <p><small>Please note: some prices may vary depending on case and treatment plan needed.</small>
              <br/><small><em>*</em> Prices correct as of January 2023.</small></p>
            All cosmetic treatment will be quoted individually.
            <br/>An itemised estimate will be provided for all treatment quoted.
            <br/>All fees quoted will be held for 90 days.
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page